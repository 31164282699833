<template>
	<v-dialog :value="true" min-width="400" max-width="400" noClickAnimation overlay-opacity=".75">
		<v-card>
            <timer-card :timer="timer" @timerUpdated="onUpdateTimer" hideStartSubmitButtons class="card d-flex flex-column justify-space-between mb-0 px-1"></timer-card>

<!--			<v-card-actions>-->
<!--				<v-btn @click="modal.trigger('close')" class="ml-auto">Close</v-btn>-->
<!--			</v-card-actions>-->

		</v-card>
	</v-dialog>
</template>

<script>
import TimerCard from "@/components/timerCard";

	export default {
		name: "timerEdit",

		components: {
            TimerCard,
		},

		props: {
			modal: {
				type: Object,
				required: true,
			},
			timer: {
				type: Object,
				default: null,
			},
		},

		data() {
			return {
			};
		},

		methods: {
            onUpdateTimer(e) {
                this.modal.trigger('update',e)
                this.$snotify.success("Success!", `Timer updated.`);
            },

		},
	};
</script>
